import React, { useState, useCallback, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useDropzone } from "react-dropzone";
import SecurityAllocation from "./SecurityAllocation";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import secureLocalStorage from "react-secure-storage";
import fileContext from "../context/fileContext";
import { useParams } from "react-router-dom";
import { api } from "../utils/axios-instance";
import { auth, storage } from "../helper/firebaseClient";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import useAuth from "../stores/authStore";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "green" : "green",
  },
}));

const SecureShare = ({ value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState({
    geoLocation: false,
    uniqueIdentifiers: false,
    accessControl: false,
  });
  const [securityAllotmentData, setSecurityAllotmentData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [depts, setDepts] = useState([]);
  const [message, setMessage] = useState("");
  const [recieverEmail, setRecieverEmail] = useState("");
  const { deptName } = useParams();
  const context = useContext(fileContext);
  const { updateFilesState, updateDepartmentFiles } = context;
  const profileData = useAuth((state) => state.profileData);
  const [deptId, setDeptId] = useState("");
  const [selectedDeptIndex, setSelectedDeptIndex] = useState(null);

  const handleDepartmentClick = (index, id) => {
    console.log("Selected department id:", id);
    setDeptId(id);
    setSelectedDeptIndex(index);
  };
  const uploadFile = async (file, id) => {
    console.log({ profileData, file, id });
    return new Promise((resolve, reject) => {
      const fileRef = ref(storage, `files/${profileData.org}/${id}`);
      const metadata = {
        customMetadata: {
          department_id: deptId,
          org_id: profileData.org,
        },
      };

      const uploadTask = uploadBytesResumable(fileRef, file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("File upload error:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const useFinalUpload = useMutation({
    mutationKey: ["upload-secure-shared-file"],
    mutationFn: async () => {
      if (!deptId) {
        toast.error("Please select a department");
        return;
      }
      if (securityAllotmentData.selectedUsers.length === 0) {
        toast.error("Please select at least one user");
        return;
      }
      for (const file of selectedFiles) {
        const newFileId = uuidv4();

        const downloadURL = await uploadFile(file, newFileId);
        console.log("uploaded file:", downloadURL);
        await handleFileIdRetrieval(file, file.name, downloadURL, newFileId);
      }
    },
    onSuccess: () => {
      showSnackbar("Upload successful", "success");
      setSelectedFiles([]);
      setUploadProgress(0);
    },
    onError: (error) => {
      console.error("Error occurred in file upload:", error);
      showSnackbar("Upload failed. Please try again.", "error");
      setUploadProgress(0);
    },
  });

  const handleFileIdRetrieval = async (
    file,
    desiredFileName,
    downloadURL,
    newFileId
  ) => {
    const token = await auth.currentUser.getIdToken();
    try {
      const res = await api.post(`/file/addDepartment/${newFileId}`, {
        department_ids: [deptId],
        new: true,
        name: desiredFileName,
        downloadURL: downloadURL,
        idToken: token,
        metadata: {
          size: file.size,
          mimetype: file.type,
          lastModified: new Date().toLocaleString("en-IN", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
        },
      });

      console.log("File ID retrieval response:", res.data);
      // showSnackbar(res.data.detail, "success");
      toast.success("File uploaded successfully");

      updateFilesState(value);
      console.log("deptName", deptName, value);
      if (deptName) {
        addFileDepartment(newFileId);
        updateDepartmentFiles(deptName);
      }
      shareFile({ fileId: newFileId });
    } catch (error) {
      console.log(error);
      // showSnackbar("Error retreiving data", "error");
      toast.error("Error uploading file");
    }
  };

  const addFileDepartment = async (fileId) => {
    try {
      let profileData = JSON.parse(secureLocalStorage.getItem("profileData"));
      let departmentName = profileData.dept;

      // Find the department object based on its name
      const department = depts.find((dept) => dept.name === departmentName);
      console.log(department);

      if (department) {
        let body = {
          department_ids: [department.id],
        };

        const addDept = await api.post(`/file/addDepartment/${fileId}`, body);

        console.log("dept added to file", addDept);
        updateFilesState(value);
        if (deptName) updateDepartmentFiles(deptName);
      } else {
        console.log(`Department "${departmentName}" not found.`);
      }
    } catch (error) {
      console.log("Error occured while adding the file department", error);
    }
  };

  const shareFile = async ({ fileId }) => {
    try {
      const token = await auth.currentUser.getIdToken();

      if (recieverEmail.trim() !== "") {
        console.log("recieverEmail", recieverEmail);
        const res = await api.post(`/file/shareAFileThroughEmail`, {
          fileId: fileId,
          to: recieverEmail,
          idToken: token,
          message: message,
        });

        console.log("shareFiles:", res);

        showSnackbar("File shared successfully", "success");

        setTimeout(() => {
          closeDialog();
        }, 2000);
      }
      if (securityAllotmentData.selectedUsers.length > 0) {
        const res = await api.post(`/file/shareFile`, {
          file: [fileId],
          shared_with: securityAllotmentData.selectedUsers,
          expiration_time: securityAllotmentData.timeDifference
            ? securityAllotmentData.timeDifference
            : 31536000 * 5,
          security_check: {
            download_enabled: true,
            geo_enabled: securityAllotmentData.location,
          },
          idToken: token,
        });

        console.log("shareFiles:", res);

        // Show snackbar on successful file sharing
        showSnackbar("File shared successfully", "success");

        setTimeout(() => {
          closeDialog();
        }, 2000);
      }
    } catch (error) {
      console.log("error occurred while setting the permissions", error);
      // Show snackbar on error
      if (error.response.status === 406) {
        showSnackbar("File already shared", "error");
      } else {
        showSnackbar(error.error || "Error sharing file", "error");
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleRemoveFile = (fileIndex) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(fileIndex, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleCheckboxChange = (checkbox) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [checkbox]: !prevValues[checkbox],
    }));
  };

  function handleSecurityAllocation(data) {
    setSecurityAllotmentData(data);
    console.log("handleSecurityAllocation", data);
  }

  const openDialog = () => {
    setIsOpen(true);
    const departments = JSON.parse(secureLocalStorage.getItem("departments"));

    setDepts(departments);
    console.log("upload files", departments);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setSelectedFiles([]);
    setUploadProgress(0);
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Update the selected files array
    setSelectedFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div className="">
      <button
        onClick={openDialog}
        className="py-1 px-4 rounded-md border bg-blue-700 hover:bg-blue-500 text-white"
      >
        Secure Share
      </button>

      <Dialog
        open={isOpen}
        onClose={closeDialog}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: "5px",
          },
        }}
      >
        <DialogContent
          style={{
            backgroundColor: "#F7F8FA",
          }}
        >
          <div className="md:flex flex-row gap-4">
            <div className="">
              <div
                {...getRootProps()}
                className={`dropzone mt-2 w-full  h-[320px] md:w-[400px] flex items-center justify-center border-2 border-dashed border-blue-500 text-[#2C6ECB] bg-[#F2F7FE] p-4 rounded-md text-center cursor-pointer`}
              >
                <input {...getInputProps()} />
                <p className="text-sm">
                  Drag and drop files here, or click to select files
                </p>
              </div>
              {selectedFiles.length > 0 && (
                <div>
                  <p className="text-black text-sm font-semibold py-2 mb-2">
                    Uploading - {selectedFiles.length}{" "}
                    {selectedFiles.length === 1 ? "file" : "files"}
                  </p>
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li
                        key={file.name}
                        className="text-xs border-b-2 border-b-green-600 rounded-sm py-2 px-4 mb-1 flex items-center justify-between"
                      >
                        <span>{file.name}</span>
                        <button
                          onClick={() => handleRemoveFile(index)}
                          className="text-xl rounded-full h-4 w-4 hover:text-gray-700 focus:outline-none"
                        >
                          ×
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="w-full md:w-[400px]">
              <div>
                <p className="text-gray-600 font-semibold my-1">
                  Security Features
                </p>
                <span className="grid grid-cols-2 text-gray-800 font-semibold">
                  {/* <label>
                    <input
                      type="checkbox"
                      checked={checkboxValues.uniqueIdentifiers}
                      className="mx-1"
                      onChange={() => handleCheckboxChange("uniqueIdentifiers")}
                    />
                    Unique Identifiers
                  </label> */}
                  <label>
                    <input
                      type="checkbox"
                      checked={checkboxValues.geoLocation}
                      className="mx-1"
                      onChange={() => handleCheckboxChange("geoLocation")}
                    />
                    Geo - Location
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      checked={checkboxValues.accessControl}
                      className="mx-1"
                      onChange={() => handleCheckboxChange("accessControl")}
                    />
                    Timeframe
                  </label>
                </span>
              </div>

              <div className="my-2">
                <SecurityAllocation
                  handleSecurityAllocation={handleSecurityAllocation}
                  isOpen={isOpen}
                  checkboxValues={checkboxValues}
                  setMessage={setMessage}
                  setRecieverEmail={setRecieverEmail}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4">
                {depts.map((dept, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: dept.metadata.bg,
                      border:
                        selectedDeptIndex === index
                          ? "2px solid black"
                          : "1px solid silver", // Applying border to selected department
                    }}
                    className={`flex justify-center items-center p-2 rounded-lg cursor-pointer`}
                    onClick={() => handleDepartmentClick(index, dept.id)}
                  >
                    {dept.name.replace("_", " ")}
                  </div>
                ))}
              </div>
              {uploadProgress > 0 && (
                <BorderLinearProgress
                  variant="determinate"
                  value={uploadProgress}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "10px", backgroundColor: "#F7F8FA" }}>
          <button
            className="px-4 py-1 mx-2 rounded-lg shadow-sm border bg-[#D1293D] hover:bg-red-500 text-white"
            onClick={closeDialog}
            color="primary"
          >
            Discard
          </button>
          <button
            className={`px-4 py-1 rounded-lg shadow-sm text-white ${
              selectedFiles.length
                ? "bg-[#5E5ADB] hover:bg-blue-500"
                : "bg-gray-400"
            } `}
            onClick={() => useFinalUpload.mutate()}
            disabled={useFinalUpload.isPending}
          >
            {useFinalUpload.isPending ? "Uploading..." : "Share"}
          </button>
        </DialogActions>

        {uploadProgress > 90 && (
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        )}
      </Dialog>
    </div>
  );
};

export default SecureShare;
