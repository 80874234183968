import React, { useState, useEffect, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDarkMode } from "../context/darkModeContext";
import FileView from "./FileView";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { api } from "../utils/axios-instance";
import { useLocation } from "react-router-dom";
import useAuth from "../stores/authStore";
import fileContext from "../context/fileContext";
import { auth } from "../helper/firebaseClient";
import { Dialog, DialogContent } from "@mui/material";
import { fileIcons } from "../utils/icon-mimetypes";
import Avatar from "@mui/material/Avatar";
import FileBg from "../generators/fileBGGenerator";
import CircularProgress from "@mui/material/CircularProgress";

const DefaultIcon = `${process.env.PUBLIC_URL}/assets/defaulticon.webp`;

export default function SearchBar() {
  const [showSearchBar, setShowSearchBar] = useState(false);

  return (
    <div className="flex items-end flex-1 text-lg">
      <SearchIcon
        onClick={() => setShowSearchBar(true)}
        className="hover:cursor-pointer"
      />

      {showSearchBar && (
        <Search
          open={showSearchBar}
          closeDialog={() => setShowSearchBar(false)}
        />
      )}
    </div>
  );
}

function Search({ open, closeDialog }) {
  const { darkMode } = useDarkMode();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [isFileViewOpen, setIsFileViewOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedFileInfo, setSelectedFileInfo] = useState({
    name: "",
    size: "",
    id: "",
    owner: "",
    profileUrl: "",
    lastUpdate: "",
  });
  const [sharedFileInfo, setSharedFileInfo] = useState({});
  const [dept, setDept] = useState({
    Account: { bg: "#FFF6F6", border: "#FEB7B7" },
    Finance: { bg: "#FFF6FF", border: "#FFA9FF" },
    Development: { bg: "#F6FFF6", border: "#B3FFB3" },
    Manufacturing: { bg: "#F6F7FF", border: "#B6BEFF" },
    Sales: { bg: "#FFFFF6", border: "#FFFFA1" },
    Human_Resources: { bg: "#F6FFFE", border: "#C0FFF8" },
  });
  const location = useLocation();
  const profileData = useAuth((state) => state.profileData);
  const formatFileSize = useAuth((state) => state.formatFileSize);
  const [filesWithTerm, setFilesWithTerm] = useState([]);
  const { allFiles } = useContext(fileContext);
  const [loading, setLoading] = useState(false);

  const getSharedFileInfo = async (fileId) => {
    try {
      const info = await api.get(`/file/sharedFileInfo/${fileId}`);
      setSharedFileInfo(info.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openDrawer = (
    fileName,
    fileSize,
    fileId,
    owner,
    profilePic,
    lastUpdate,
    mimetype
  ) => {
    getSharedFileInfo(fileId);
    console.log(fileName);
    setSelectedFileInfo({
      name: fileName,
      size: fileSize,
      id: fileId,
      owner: owner,
      ownerProfileUrl: profilePic,
      lastUpdate: lastUpdate,
      mimetype: mimetype,
    });
    setIsFileViewOpen(true);
    // Close the search bar when a file is selected
    setSearchTerm("");
  };

  const closeDrawer = () => {
    setIsFileViewOpen(false);
    closeDialog();
  };

  useEffect(() => {
    const filesData = JSON.parse(
      secureLocalStorage.getItem("recentFilesCache")
    );
    // console.log(filesData);

    if (filesData) {
      // Filter files based on the search term
      const filteredFiles = filesData.filter((file) =>
        file?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setFilteredFiles(filteredFiles);
    }
  }, [searchTerm]);

  useEffect(() => {
    const listUsers = async () => {
      try {
        const response = await api.get(`/users/list_users`);

        setUsers(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    listUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      const fetchUserData = async () => {
        const filtered = users.filter((user) =>
          user?.name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        console.log(filtered);
        setSearchResults(filtered);
      };

      fetchUserData();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, users]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUserClick = (id) => {
    // Navigate to the "user-profile" route with the user ID or any relevant parameter
    navigate(`/user-profile/${id}`);
    // Close the search bar when a user is selected
    setSearchTerm("");
    closeDialog();
  };

  const getIconByMimeType = (mimeType) => {
    return fileIcons[mimeType] || DefaultIcon;
  };

  const handleTextSearch = async () => {
    setLoading(true);
    const org_id = profileData.org;

    const response = await api.get("/file/getFilesWithTerm/", {
      params: {
        searchTerm,
        org_id,
      },
    });

    setFilesWithTerm(response?.data?.files);
    setLoading(false);
  };

  const handleKey = (e) => {
    if (e.code === "Backspace") setFilesWithTerm([]);
  };

  const isChat = location.pathname === "/chat";

  const fileDisplay = (file, type) => {
    return (
      <div
        key={file.id}
        className={`border border-[#ffa9ff46] p-3  rounded-[16px] cursor-pointer relative`}
        style={{
          backgroundImage: FileBg({
            gradientColors: [file.color, file.color, file.color, file.color], // Custom dynamic colors
          }),
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundBlendMode: "lighten",
        }}
        onClick={() => {
          if (type === "term") {
            const openFile = allFiles.find((f) => f.id === file.id);
            file = openFile;
          }
          openDrawer(
            file.name,
            file.size,
            file.id,
            file.owner,
            file.profilePic,
            file.lastUpdate,
            file.mimetype
          );
        }}
      >
        <div>
          <span className="flex justify-center items-center">
            <img
              src={getIconByMimeType(file?.mimetype)}
              alt="File Preview"
              className={`rounded-md my-4`}
            />
          </span>
          <span>
            <h5 className="font-semibold line-clamp-1 text-gray-700 text-sm mb-1">
              {file?.name?.split("_TS=")[0]}
            </h5>
            <span className="flex flex-row justify-between items-center">
              <span>
                <h6 className="font-semibold">
                  <span className="text-xs font-bold text-[10px] text-[#676767]">
                    {type === "term" ? formatFileSize(file?.size) : file.size}
                  </span>
                </h6>
              </span>

              <Avatar
                src={file?.profilePic}
                alt="owner pic"
                sx={{
                  width: 24,
                  height: 24,
                  border: "1px solid silver",
                }}
              />
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      PaperProps={{
        style: {
          borderRadius: "5px",
          width: "80%",
          height: "90%",
          padding: "0px",
        },
      }}
      maxWidth="lg"
    >
      <DialogContent
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        <div
          className={`relative w-full ${isChat ? "md:w-[90%] self-center" : "md:w-full"} self-center h-full`}
        >
          <div className="relative flex">
            <SearchIcon
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: "10px",
                color: "#808080",
              }}
            />
            <input
              type="search"
              placeholder="Search"
              className={`w-[85%] p-1 px-10 ${
                darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-700"
              } rounded-md`}
              style={{ outline: "none" }}
              value={searchTerm}
              onChange={handleSearchChange}
              name="searchQuery"
              id="search-bar"
              autoComplete="off"
              onKeyDown={handleKey}
              autoFocus
            ></input>
            <button
              className="py-1 px-4 rounded-md border bg-[#1c4ed8] mr-2 text-white"
              onClick={handleTextSearch}
            >
              Search Text
            </button>
            {/* Hidden fields to trick autofill */}
            <input
              type="email"
              style={{ display: "none" }}
              autoComplete="email"
            />
            <input
              type="password"
              style={{ display: "none" }}
              autoComplete="new-password"
            />
          </div>

          {/* Display search results */}
          {searchTerm && (
            <div className="h-full">
              <h3 className="text-md font-semibold p-4">Files:</h3>
              <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,250px))] gap-4 overflow-y-auto">
                {filteredFiles.map((file) => fileDisplay(file, "name"))}
              </div>
              <h3 className="text-md font-semibold p-4">Files With Term:</h3>
              <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,250px))] gap-4 relative">
                {loading ? (
                  <>
                    <CircularProgress
                      size={50}
                      color="inherit"
                      className="absolute left-[50%] top-4"
                    />
                    <div className="h-16"></div>
                  </>
                ) : (
                  filesWithTerm.map((file) => fileDisplay(file, "term"))
                )}
              </div>
              <h3 className="text-md font-semibold p-4">Users:</h3>
              <div className="grid grid-cols-[repeat(auto-fit,96px)] gap-4 max-h-[33%]">
                {searchResults.map((user) => {
                  return (
                    <div
                      className="flex flex-col items-center hover:cursor-pointer"
                      onClick={() => handleUserClick(user.id)}
                    >
                      <Avatar
                        src={user.profilePictureUrl}
                        alt="user pic"
                        sx={{
                          width: 96,
                          height: 96,
                          border: "1px solid silver",
                        }}
                      />
                      <h3 className="mt-2 font-semibold">{user.name}</h3>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {isFileViewOpen && (
            <FileView
              fileInfo={selectedFileInfo}
              closeDrawer={closeDrawer}
              sharedFileInfo={sharedFileInfo}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
